import { Injectable } from '@angular/core';
import {
  Club,
  ClubCourtsAvailabilityGQL,
  ClubCourtsAvailabilityInput,
  ClubPublicByIdGQL,
  ClubCourtsAvailability,
  DurationPrice,
  BookableSlot,
  PaymentInitializeForCourtReservationInput,
  PaymentInitializeForCourtReservationGQL,
  InitializedPayment,
  CourtReservation,
  CourtReservationCreateByCashPaymentInput, CourtReservationCreateByCashPaymentGQL, ClubPublicBySlugGQL,
  CourtReservationCreateFreeInput,
  CourtReservationCreateFreeGQL,
} from "../../../shared/graphql/generated/graphql";
import { debounceTime, map, Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  mapApolloMutationResultDataOrThrowOnError,
  mapApolloQueryResultDataOrThrowOnError
} from "../../../shared/graphql/graphql-utils";

interface Blocker {
  startTime: Date;
  endTime: Date;
  courtId: string;
}

@Injectable({
  providedIn: 'root'
})
export class ClubsService {

  constructor(private clubPublicByIdGQL: ClubPublicByIdGQL,
              private clubPublicBySlugGQL: ClubPublicBySlugGQL,
              private clubCourtsAvailabilityGQL: ClubCourtsAvailabilityGQL,
              private paymentInitializeForCourtReservationGQL: PaymentInitializeForCourtReservationGQL,
              private courtReservationCreateByCashPaymentGQL: CourtReservationCreateByCashPaymentGQL,
              private courtReservationCreateFreeGQL: CourtReservationCreateFreeGQL,
  ) {}

  getCourtsAvailability(data: ClubCourtsAvailabilityInput): Observable<ClubCourtsAvailability> {
    console.log('getCourtsAvailability pre-fetch input', data);
    return this.clubCourtsAvailabilityGQL.fetch({
        data: {
          clubId: data.clubId,
          sportIds: data.sportIds,
          startDate: data.startDate,
          endDate: data.endDate,
        },
      }).pipe(
        mapApolloQueryResultDataOrThrowOnError(),
        map(data => {
          console.log('getCourtsAvailability raw result', data);
          const tmp = {
            ...data.clubCourtsAvailability,
            startTime: new Date(data.clubCourtsAvailability.startTime),
            endTime: new Date(data.clubCourtsAvailability.endTime),
          } as ClubCourtsAvailability
          console.log('getCourtsAvailability result', tmp);
          return tmp;
        })
      );
  }

  getClubById(clubId: string): Observable<Club> {
    return this.clubPublicByIdGQL.fetch({ id: clubId }).pipe(
      mapApolloQueryResultDataOrThrowOnError(),
      map(data => {
        return data.clubPublic as Club;
      }),
    );
  }

  getClubBySlug(slug: string): Observable<Club> {
    return this.clubPublicBySlugGQL.fetch({ slug: slug }).pipe(
      mapApolloQueryResultDataOrThrowOnError(),
      map(data => {
        return data.clubFindBySlug as Club;
      }),
    );
  }

  initializePayment(data: PaymentInitializeForCourtReservationInput): Observable<InitializedPayment> {
    return this.paymentInitializeForCourtReservationGQL.mutate({
      data: data,
    }).pipe(
      mapApolloMutationResultDataOrThrowOnError(),
      map(data => data.paymentInitializeForCourtReservation as InitializedPayment)
    );
  }

  courtReservationByCashPayment(data: PaymentInitializeForCourtReservationInput): Observable<CourtReservation> {
    return this.courtReservationCreateByCashPaymentGQL.mutate({
      data: data,
    }).pipe(
      mapApolloMutationResultDataOrThrowOnError(),
      map(data => data.courtReservationCreateByCashPayment as CourtReservation)
    );
  }

  courtReservationByFree(data: CourtReservationCreateFreeInput): Observable<CourtReservation> {
    return this.courtReservationCreateFreeGQL.mutate({
      data: data,
    }).pipe(
      mapApolloMutationResultDataOrThrowOnError(),
      map(data => data.courtReservationCreateFree as CourtReservation)
    );
  }
}
