import { Component, computed, effect, inject, signal } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InitializedPayment, MerchantMembershipType, PaymentInitializeForItemsInput, SetupPaymentForItemsOutput } from '../../../../shared/graphql/generated/graphql';
import { CheckoutServiceService } from '../../services/checkout-service.service';
import { Store } from '@ngrx/store';
import { selectAuthIsLoggedIn } from '../../../auth/store/auth.selectors';
import { selectPlayerSelfActiveMembershipTypes, selectPlayerSelfIsOnboarded } from '../../../player-self/store/player-self.selectors';
import { combineLatestWith, filter, map, Observable, of } from 'rxjs';
import { AuthModule } from "../../../auth/auth.module";
import { AsyncPipe, CurrencyPipe, DatePipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { DateInTimezonePipe } from "../../../../shared/pipes/date-in-timezone.pipe";
import { CheckoutDialogComponent } from "../checkout-dialog/checkout-dialog.component";
import { CheckoutParams } from '../stripe-checkout/stripe-checkout.component';
import { environment } from '../../../../../environments/environment';
import { CheckoutModule } from '../../checkout.module';
import { ButtonModule } from 'primeng/button';

export interface CartCheckoutDialogData {
  eventId?: string;
  courtReservationSlot?: {
    courtId: string;
    startTime: Date;
    duration: number;
  },
  eligibleMerchantMembershipTypes: MerchantMembershipType[];
}

@Component({
  selector: 'cs-cart-checkout-dialog',
  standalone: true,
  imports: [AuthModule, AsyncPipe, DateInTimezonePipe, CurrencyPipe, NgIf, DatePipe, NgTemplateOutlet, CheckoutModule, ButtonModule],
  templateUrl: './cart-checkout-dialog.component.html',
  styleUrl: './cart-checkout-dialog.component.scss'
})
export class CartCheckoutDialogComponent {

  dialogRef = inject(DynamicDialogRef);
  dialogConf = inject(DynamicDialogConfig);

  checkoutService = inject(CheckoutServiceService);

  store = inject(Store);

  cartConfig: PaymentInitializeForItemsInput | undefined;

  cart = signal<SetupPaymentForItemsOutput | undefined>(undefined);
  cartLoading = signal(false);

  eligibleMerchantMembershipTypes: MerchantMembershipType[] = [];

  checkoutParams = signal<CheckoutParams | undefined>(undefined);
  checkoutParams$ = toObservable(this.checkoutParams).pipe(
    filter(params => params !== undefined),
    map(params => params!)
  );

  playerSelfActiveMemberships = toSignal(this.store.select(selectPlayerSelfActiveMembershipTypes));
  playerSelfActiveMembershipTypeIds = computed(() => {
    const ids = this.playerSelfActiveMemberships()?.map(membership => membership.merchantMembershipTypeId) ?? [];
    console.log('playerSelfActiveMembershipTypeIds', ids);
    return ids;
  });

  cashPaymentPossible = signal(true);

  showMembershipSelection = computed(() => {
    if (this.eligibleMerchantMembershipTypes.length == 0) {
      return false;
    }
    if (this.cart()?.merchantMemberships && this.cart()!.merchantMemberships.length > 0) {
      return false;
    }
    /*if (this.playerSelfActiveMembershipTypeIds().some(id => this.eligibleMerchantMembershipTypes.some(mt => mt.id == id))) {
      return false;
    }*/
    return true;
  });

  showLoginAndOnboarding$ = this.store.select(selectAuthIsLoggedIn).pipe(
    combineLatestWith(this.store.select(selectPlayerSelfIsOnboarded)),
    map(([isLoggedIn, isOnboarded]) => !isLoggedIn || !isOnboarded)
  )

  showLoginAndOnboarding = toSignal(this.showLoginAndOnboarding$);

  fetchCart() {
    if (!this.cartConfig) {
      return;
    }
    console.log('fetchCart');
    this.cartLoading.set(true);
    this.checkoutService.setupPaymentForItems(this.cartConfig!).subscribe({
      next: (cart) => {
        this.cart.set(cart);
        this.dialogConf.width = '900px';
        this.checkoutParams.set({
          returnPath: '/clubs',
          price: cart.total,
          paymentMethods: [],
          stripePublishableKey: environment.stripePublishableKey,
        });
      },
      error: (error) => {
        
      },
      complete: () => {
        this.cartLoading.set(false);
      }
    });
  }

  initializePayment(params: CheckoutParams): Observable<InitializedPayment> {
    // return this.checkoutService.initializePaymentForItems(params);
    return of({
      clientSecret: 'test',
      id: 'test',
      customerId: 'test',
      paymentIntentClientSecret: 'test',
      publishableKey: 'test',
    });
  }

  checkoutByCashPayment(params: CheckoutParams): Observable<any> {
    console.log('checkoutByCashPayment', params);
    return of({});
  }

  getCheckoutParamsObservable(): Observable<CheckoutParams> {
    return this.checkoutParams$.pipe(
      filter(params => params !== undefined),
      map(params => params!)
    );
  }

  constructor() {
    effect(() => {
      if (!this.showLoginAndOnboarding() && !this.cart() && this.cartConfig && !this.cartLoading()) {
        this.fetchCart();
      }
    });
  }

  onMembershipTypeSelected(membershipType: MerchantMembershipType) {
    if (membershipType.externalPurchaseUrl) {
      window.open(membershipType.externalPurchaseUrl, '_blank');
    } else {
      this.cartConfig!.merchantMemberships = [{
        merchantMembershipTypeId: membershipType.id,
        cartPosition: 1,
      }];
      this.fetchCart();
    }
  }

  ngOnInit() {
    const data = this.dialogConf.data as CartCheckoutDialogData;
    this.eligibleMerchantMembershipTypes = data.eligibleMerchantMembershipTypes;
    this.cartConfig = {
      events: data.eventId ? [{
        eventId: data.eventId,
        quantity: 1,
        cartPosition: 0,
      }] : [],
      courtReservations: data.courtReservationSlot ? [{
        courtId: data.courtReservationSlot.courtId,
        startTime: data.courtReservationSlot.startTime,
        duration: data.courtReservationSlot.duration,
        cartPosition: 0,
      }] : [],
      merchantMemberships: []
    }

    if (!this.showLoginAndOnboarding() && !this.cartLoading() && !this.cart()) {
      this.fetchCart();
    }
  }
}

