<div class="flex pt-3">
  @if (showLoginAndOnboarding$ | async) {
    <div class="flex-1 pt-1 min-h-full">
      <cs-login-with-onboarding [loginHeaderTemplate]="loginHeaderTpl">
        <ng-template #loginHeaderTpl>
          <div class="text-800 text-xl font-semibold mb-2" i18n>Login or create a guest account</div>
          <div class="text-600" i18n>Please login to your existing CircleSquare-Account or create a guest account using your preferred method to join this event</div>
        </ng-template>
      </cs-login-with-onboarding>
    </div>
  } 
  @else if (cartLoading() && !cart()) {
    <div class="flex-1 pt-1 min-h-full mb-3">
      <div class="flex justify-content-center align-items-center">
        <i class="pi pi-spinner p-icon-spin text-primary font-medium" style="font-size: 2rem"></i>
      </div>
    </div>
  }
  @else {
    <div class="flex-1 pt-1 min-h-full">
      @if (showMembershipSelection()) {
        <div>
          <h4 class="text-xl font-semibold mb-2">Membership required</h4>
          <p class="text-600 mb-3">One of the following memberships is required to proceed</p>

          <div class="flex flex-column gap-3">
            @for (membershipType of eligibleMerchantMembershipTypes; track membershipType.id) {
              <div class="border-1 surface-border border-round p-3 flex justify-content-between align-items-center cursor-pointer hover:bg-surface-hover" (click)="onMembershipTypeSelected(membershipType)">
                <div class="flex flex-column justify-content-center">
                  <div class="text-xl font-medium">{{ membershipType.name }}</div>
                  <p *ngIf="membershipType.description && membershipType.description.trim().length > 0">{{ membershipType.description }}</p>
                </div>
                <div class="flex justify-content-center align-items-center">
                  <div class="text-xl font-medium text-primary">{{ membershipType.price | currency:'EUR':'symbol' }}</div>
                  <i class="pi pi-chevron-right ml-2 text-400 "></i>
                </div>
              </div>
            }
          </div>
        </div>
      } @else if (checkoutParams()) {
        <cs-stripe-checkout [overviewTemplate]="overviewTemplate"
                    [disclaimerTemplate]="disclaimerTemplate"
                    [alternativePaymentOptionTemplate]="cashPaymentPossible() ? alternativePaymentOptionTemplate : undefined"
                    [alternativePaymentMethodFn]="checkoutByCashPayment.bind(this)"
                    [checkoutParams$]="checkoutParams$"
                    [initializePaymentFn]="initializePayment.bind(this)"
        >
        </cs-stripe-checkout>
      }
    </div>
    <div class="pl-3 pr-3 hidden md:block">
      <div class="flex relative align-items-center justify-content-center mx-3 py-3 min-h-full">
        <div class="border-left-1 surface-border top-0 left-50 absolute h-full"></div>
      </div>
    </div>
    <div class="flex-1 hidden md:block">
      <ng-container *ngTemplateOutlet="overviewTemplate"></ng-container>
    </div>
  }  
</div>

<ng-template #overviewTemplate>
  <ng-container>
    @if (cart()?.courtReservations && cart()!.courtReservations!.length > 0) {
      @for (slot of cart()!.courtReservations!; track slot.cartPosition) {
        <div class="flex my-3 px-1">
        <div class="flex flex-column justify-content-center flex-1">
          <div class="text-lg text-900 font-medium">{{ slot.court.club.name }} - {{ slot.court.name }}</div>
          <div class="text-500 text-sm">{{ slot.startTime | dateInTimezone:'short':slot.court.club.ianaTimezone! }} | {{ slot.duration }} min</div>
        </div>
        <div class="text-lg text-900 font-medium">{{ slot.price | currency:'EUR':'symbol' }}</div>
      </div>
      }
      <div class="border-top-1 surface-border w-full"></div>
    }

    @if (cart()?.merchantMemberships && cart()!.merchantMemberships!.length > 0) {
      @for (membership of cart()!.merchantMemberships!; track membership.cartPosition) {
        <div class="flex my-3 px-1">
          <div class="flex flex-column justify-content-center flex-1">
            <div class="text-lg text-900 font-medium">{{ membership.merchantMembershipType.name }}</div>
            <div class="text-500 text-sm">{{ membership.startDate | date:'short' }}</div>
          </div>
          <div class="text-lg text-900 font-medium">{{ membership.price | currency:'EUR':'symbol' }}</div>
        </div>
      }
      <div class="border-top-1 surface-border w-full"></div>
    }

    <div class="flex justify-content-between my-3 px-1">
      <div>
        <div class="text-800 font-medium" i18n>Total</div>
        <div class="text-500 text-sm" i18n>incl. VAT.</div>
      </div>
      <div class="text-500 text-2xl text-primary">{{ cart()?.total | currency:'EUR':'symbol' }}</div>
    </div>  
  </ng-container>
</ng-template>

<ng-template #disclaimerTemplate>
  <ng-container>
    <div class="pt-4 pb-2">
      <div class="text-500 text-xs">
        <p i18n>By continuing you agree to our <a href="/terms-of-service" class="text-primary">terms of sale</a> and <a href="/privacy-policy" class="text-primary">privacy policy</a>.</p>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #alternativePaymentOptionTemplate let-onClick="onClick" let-loading="loading" let-disabled="disabled">
  <p-button severity="secondary" styleClass="w-full justify-content-center" (click)="onClick()" [loading]="loading()" [disabled]="disabled()">
    <div class="flex flex-row align-items-center">
      <div class="flex flex-column line-height-1">
        <div class="flex flex-row align-self-center align-items-center">
          <i class="pi pi-money-bill mr-2"></i>
          <span i18n class="font-medium">Pay cash on site</span>
        </div>
        <!--<div>
          <span class="text-xs" i18n>Only for club members</span>
        </div>-->
      </div>

      <div class="text-xl font-medium ml-4">
        {{ cart()?.total | currency:'EUR':'symbol' }}
      </div>
    </div>
  </p-button>
</ng-template>