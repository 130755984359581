// stores/club.store.ts
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { computed, inject } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { Router } from '@angular/router';
import { Club, Location, MerchantMembershipType, PaymentMethod } from "../../../../shared/graphql/generated/graphql";
import { ClubsService } from "../../services/clubs.service";
import { tapResponse } from '@ngrx/operators';
import { CourtsAvailabilityConfig } from "../../components/court-availability/courts-availability.store";

type ClubState = {
  club: Club | null;
  loading: boolean;
  error: string | null;
};

const initialState: ClubState = {
  club: null,
  loading: false,
  error: null,
};

export const ClubStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((store, clubsService = inject(ClubsService), router = inject(Router)) => ({
    fetchClub(id: string) {
      patchState(store, { loading: true, error: null });
      return clubsService.getClubById(id).pipe(
        tapResponse({
          next: (club) => {
            patchState(store, { club, loading: false });
          },
          error: (error: any) => {
            patchState(store, { error: error.message, loading: false });
            // router.navigate(['/404']);
            return EMPTY;
          },
        })
      );
    },
    setClub(club: Club) {
      patchState(store, { club });
    }
  })),
  withComputed(({ club }) => ({
    hasClub: computed(() => !!club()),
    sportIds: computed(() => club()?.sports.map(sport => sport.sportId) || []),
    backgroundImageStyle: computed(() => {
      const backgroundImageUrl = club()?.images[0]?.url;
      if (backgroundImageUrl) {
        return `linear-gradient(to right, rgba(0, 0, 0, 0.8), transparent), url('${backgroundImageUrl}')`;
      }
      return ''
    }),
    cashPaymentEnabled: computed(() => club()?.paymentMethods.includes(PaymentMethod.Cash)),
    additionalLocations: computed<Location[]>(() => (club()?.locations ?? []).filter(location => location.id !== club()?.primaryLocation?.id)),
  })),
  withComputed(({ club, sportIds }) => ({
    courtAvailabilityConfig: computed((): CourtsAvailabilityConfig | undefined => {
      if (!club()) {
        return;
      }

      return {
        sportIds: sportIds(),
        clubId: club()!.id,
        timezone: (club()!.ianaTimezone as string ?? Intl.DateTimeFormat().resolvedOptions().timeZone),
        courts: club()!.courts,
      }
    })
  }))
);

export type ClubStore = InstanceType<typeof ClubStore>;
