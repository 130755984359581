import { inject, Injectable } from '@angular/core';
import { PaymentInitializeForItemsInput, SetupPaymentForItemsGQL, SetupPaymentForItemsOutput } from '../../../shared/graphql/generated/graphql';
import { map, Observable } from 'rxjs';
import { mapApolloQueryResultDataOrThrowOnError } from '../../../shared/graphql/graphql-utils';

@Injectable({
  providedIn: 'root'
})
export class CheckoutServiceService {

  #setupPaymentForItemsGQL = inject(SetupPaymentForItemsGQL);
  
  setupPaymentForItems(data: PaymentInitializeForItemsInput): Observable<SetupPaymentForItemsOutput> {
    return this.#setupPaymentForItemsGQL.fetch({ data: data }).pipe(
      mapApolloQueryResultDataOrThrowOnError(),
      map(data => {
        return data.setupPaymentForItems as SetupPaymentForItemsOutput;
      }),
    );
  }
}
