import {
  AfterViewInit,
  Component, ComponentRef,
  computed,
  DestroyRef,
  effect,
  ElementRef, EmbeddedViewRef, Host,
  Inject,
  inject,
  Input,
  LOCALE_ID, NgZone, OnDestroy, Optional, PLATFORM_ID,
  Renderer2, TemplateRef,
  ViewChild, ViewContainerRef
} from '@angular/core';
import { CourtsAvailabilitySlotComponent } from "../courts-availability-slot/courts-availability-slot.component";
import { DatePipe, formatCurrency, formatDate, isPlatformBrowser, NgForOf, NgIf } from "@angular/common";
import { CourtsAvailabilitySelectedSlotStore } from "./courts-availability-selected-slot.store";
import { CourtsAvailabilityStore } from "../../../courts-availability.store";
import { Button } from "primeng/button";
import { DurationPrice } from "../../../../../../../shared/graphql/generated/graphql";
import { CourtsAvailabilityBookableSlot } from "../../../../../entities/courts-availability-bookable-slot";
import { takeUntilDestroyed, toObservable, toSignal } from "@angular/core/rxjs-interop";
import {
  CourtReservationCheckoutDialogComponent
} from "../../../../court-reservation-checkout-dialog/court-reservation-checkout-dialog.component";
import { DialogService } from "primeng/dynamicdialog";
import { ClubStore } from "../../../../../pages/club-detail-page/club-detail-page.store";
import { ActivatedRoute, Router } from "@angular/router";
import { DateInTimezonePipe } from "../../../../../../../shared/pipes/date-in-timezone.pipe";
import { IframeDialogService } from "../../../../../../../shared/iframe-dialog/iframe-dialog.service";
import { DateTime } from "luxon";
import { environment } from "../../../../../../../../environments/environment";
import { CdkPortal, ComponentPortal, DomPortalOutlet, TemplatePortal } from "@angular/cdk/portal";
import {
  CdkConnectedOverlay,
  CdkOverlayOrigin, CdkScrollable,
  ConnectionPositionPair,
  Overlay,
  OverlayRef
} from "@angular/cdk/overlay";
import { NgxTippyModule, NgxTippyService } from "ngx-tippy-wrapper";
import { NgxTippyProps, NgxTippyDirective } from 'ngx-tippy-wrapper';
import { TippyService } from "@ngneat/helipopper";
import { OverlayClickOutsideDirective } from "../../../../../../../shared/directives/overlay-click-outside.directive";
import { take } from "rxjs/operators";
import { selectPlayerSelfActiveMembershipTypes } from '../../../../../../player-self/store/player-self.selectors';
import { Store } from '@ngrx/store';
import { ClubsService } from '../../../../../services/clubs.service';
import { showErrorToast } from '../../../../../../../shared/store/toast/toast.actions';
import { CartCheckoutDialogComponent, CartCheckoutDialogData } from '../../../../../../checkout/components/cart-checkout-dialog/cart-checkout-dialog.component';

@Component({
  selector: 'cs-courts-availability-selected-slot',
  standalone: true,
  imports: [
    Button,
    NgForOf,
    NgIf,
    DatePipe,
    DateInTimezonePipe,
    CdkPortal,
    NgxTippyModule,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    OverlayClickOutsideDirective
  ],
  templateUrl: './courts-availability-selected-slot.component.html',
  styleUrl: './courts-availability-selected-slot.component.scss'
})
export class CourtsAvailabilitySelectedSlotComponent extends CourtsAvailabilitySlotComponent implements AfterViewInit, OnDestroy {

  protected readonly formatDate = formatDate;

  @ViewChild('selectedSlotTooltip') selectedSlotTooltip!: ElementRef;
  @ViewChild(NgxTippyDirective, { static: false }) tippy!: NgxTippyDirective;

  tippyProps: NgxTippyProps = {
    allowHTML: true,
    interactive: true,
    arrow: true,
    theme: 'light',
    maxWidth: 300,
    trigger: 'click',
    hideOnClick: false,
    appendTo: 'parent',
    popperOptions: {
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            altAxis: true,
            tether: false,
            padding: 10,
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['left', 'top', 'bottom'],
          },
        },
      ],
    },
  };

  selectedSlotStore: CourtsAvailabilitySelectedSlotStore = inject(CourtsAvailabilitySelectedSlotStore);
  availabilityStore = inject(CourtsAvailabilityStore);

  @Input({ required: true }) clubStore!: ClubStore;
  @Input() isEmbedded = false;

  private renderer = inject(Renderer2);
  private dialogService = inject(DialogService);
  private iFrameDialogService = inject(IframeDialogService);
  public locale = inject(LOCALE_ID);
  private router = inject(Router);

  destroyRef = inject(DestroyRef);

  clubsService = inject(ClubsService);

  overlay = inject(Overlay);
  elementRef = inject(ElementRef);

  private tippyService = inject(TippyService);

  // @ViewChild(CdkPortal) portal!: CdkPortal;
  @ViewChild('selectedSlotEl', { static: false }) selectedSlotEl!: ElementRef;
  @ViewChild('trigger', { static: false }) triggerEl!: ElementRef;
  @ViewChild('tooltipTemplate', { read: TemplateRef }) tooltipTemplate!: TemplateRef<any>;
  @ViewChild('tooltipContent') tooltipContentEl!: ElementRef;

  private store = inject(Store);

  private overlayRef: OverlayRef | null = null;

  private portalOutlet!: DomPortalOutlet;

  constructor(@Optional() @Host() private scrollContainer: CdkScrollable, private vcr: ViewContainerRef) {
    super();
    effect(() => {
      this.checkIfSlotToSelectIsAvailable(this.availabilityStore.bookableSlots());
    }, { allowSignalWrites: true });
  }

  slotToSelect: { startDateTime: DateTime, duration: number, courtId: string } | null = null;

  checkIfSlotToSelectIsAvailable(availableSlots:  CourtsAvailabilityBookableSlot[]) {
    if (this.slotToSelect) {
      const slot = availableSlots.find(slot => slot.startDateTime().toISO() === this.slotToSelect!.startDateTime.toISO() && slot.court.id === this.slotToSelect!.courtId);
      console.log('checkIfSlotToSelectIsAvailable - slot', slot);
      if (slot) {
        this.selectedSlotStore.selectSlot(slot, this.clubStore.club()!.ianaTimezone!);
        if (this.slotToSelect.duration && this.selectedSlotStore.selectedSlot()?.prices.find(price => price.duration === this.slotToSelect!.duration)) {
          this.selectedSlotStore.selectDuration(this.slotToSelect.duration);
          this.slotToSelect = null;
          this.router.navigate([], {
            queryParams: {
              selectedSlotStart: null, selectedSlotDuration: null
            }, queryParamsHandling: 'merge', replaceUrl: true
          });
          this.bookSlot();
        }
      }
    }
  }

  ngOnInit() {
    this.subscribeRouteQueryParams();

    /*if (isPlatformBrowser(this.platformId)) {
      const tooltipContainer = document.querySelector('.bbq2__tooltip-container');
      if (tooltipContainer) {
        this.portalOutlet = new DomPortalOutlet(tooltipContainer);
      }
    }*/
  }

  subscribeRouteQueryParams() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['selectedSlotStart'] && params['selectedSlotDuration']) {
        const selectedSlotStart = DateTime.fromISO(params['selectedSlotStart']);
        const selectedSlotDuration = parseInt(params['selectedSlotDuration']);
        const courtId = params['selectedCourt'];

        this.slotToSelect = { startDateTime: selectedSlotStart, duration: selectedSlotDuration, courtId: courtId };
        console.log('set slotToSelect from route', this.slotToSelect);
      }
    });
  }


  private previouslySelectedSlot: CourtsAvailabilityBookableSlot | null = null;

  private tippyInstance: any;

  ngOnDestroy() {
    if (this.tippyInstance) {
      this.tippyInstance.destroy();
    }
  }

  private tooltipPositioningEffect = effect(() => {
    const currentSelectedSlot = this.selectedSlotStore.selectedSlot();
    if (currentSelectedSlot && currentSelectedSlot !== this.previouslySelectedSlot) {
      // this.updateTooltipPosition();
      this.showTooltip(currentSelectedSlot);
    }

    if (this.selectedSlotStore.selectedDuration()) {
      console.log('selectedDuration update', this.selectedSlotStore.selectedDuration());
      // this.updateTooltipSize();
      // this.updateTooltipPosition();
    }
  });


  modalShown = false;

  portal!: TemplatePortal;
  tooltipComponentRef?: EmbeddedViewRef<any>;

  backdropClickSubscription: any;

  ngZone = inject(NgZone);

  showTooltip(slot: any) {
    console.log('showTooltip', slot);

    if (this.overlayRef?.hasAttached()) {
      this.overlayRef.detach();
    }

    const tooltipRef = this.overlayRef!.attach(this.portal);

    setTimeout(() => {
      this.updateTooltipSize();
      // this.updateTooltipPosition();
      // this.cdr.detectChanges();
    });
  }

  private updateTooltipSize() {
    const triggerRect = this.triggerEl.nativeElement.getBoundingClientRect();
    const tooltipRect = this.tooltipContentEl.nativeElement.getBoundingClientRect();

    console.log('updateTooltipPosition', tooltipRect);
    this.overlayRef?.updateSize({ width: tooltipRect.width, height: tooltipRect.height });

    console.log('updateTooltipPosition triggerRect', triggerRect);

    this.overlayRef?.updatePosition();
  }

  private updateTooltipPosition() {
    if (!this.overlayRef) {
      return;
    }
    const tooltipElement = this.overlayRef!.overlayElement;
    const triggerRect = this.triggerEl.nativeElement.getBoundingClientRect();
    const tooltipRect = tooltipElement.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    let top, left;

    // Positioning logic (same as before)
    if (triggerRect.right + tooltipRect.width <= viewportWidth) {
      left = triggerRect.right;
      top = triggerRect.top + (triggerRect.height - tooltipRect.height) / 2;
    } else if (triggerRect.left - tooltipRect.width >= 0) {
      left = triggerRect.left - tooltipRect.width;
      top = triggerRect.top + (triggerRect.height - tooltipRect.height) / 2;
    } else {
      left = Math.max(0, triggerRect.left);
      top = triggerRect.bottom;
    }

    // Ensure the tooltip stays within the viewport
    top = Math.max(0, Math.min(top, viewportHeight - tooltipRect.height));
    left = Math.max(0, Math.min(left, viewportWidth - tooltipRect.width));

    // Update the position strategy
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x: left, y: top })
      .withPositions([
        { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top' }
      ]);

    this.overlayRef!.updatePositionStrategy(positionStrategy);
    this.overlayRef!.updatePosition();
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit');
    this.overlayRef = this.overlay.create({
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      hasBackdrop: false,
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.triggerEl)
        .setOrigin(this.triggerEl)
        .withScrollableContainers([this.scrollContainer])
        .withPositions([
          { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' },
          { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' },
        ])
        .withFlexibleDimensions(true)
        .withPush(true)
    });
    this.portal = new TemplatePortal(this.tooltipTemplate, this.vcr);
  }

  clickOutsideOverlay() {
    this.unselectSlot();
  }

  /*private updateTooltipPosition() {
    console.log('updateTooltipPosition', this.selectedSlotEl, this.tooltipEl);
    if (this.selectedSlotEl && this.tooltipEl) {
      console.log('updateTooltipPosition');
      const slotRect = this.selectedSlotEl.nativeElement.getBoundingClientRect();
      const tooltipRect = this.tooltipEl.nativeElement.getBoundingClientRect();
      const containerRect = this.portalOutlet.outletElement.getBoundingClientRect();

      console.log('slotRect', slotRect);
      console.log('tooltipRect', tooltipRect);
      console.log('containerRect', containerRect);

      let left = slotRect.left + 10;
      let top = slotRect.top;

      // Check if tooltip would go off the right edge

      this.tooltipEl.nativeElement.style.left = `${left}px`;
      this.tooltipEl.nativeElement.style.top = `${top}px`;
    }
  }*/


  /*private tooltipPositioningEffect = effect(() => {
    const currentSelectedSlot = this.selectedSlotStore.selectedSlot();
    if (currentSelectedSlot && currentSelectedSlot !== this.previouslySelectedSlot) {
      this.setSelectedSlotTooltipPosition();
    } else {

    }
  });

  setSelectedSlotTooltipPosition() {
    const tooltip = this.selectedSlotTooltip.nativeElement;
    const tooltipRect = tooltip.getBoundingClientRect();
    const parentRect = this.el.nativeElement.getBoundingClientRect();

    const spaceAbove = parentRect.top;
    const spaceBelow = window.innerHeight - parentRect.bottom;
    const spaceLeft = parentRect.left;
    const spaceRight = window.innerWidth - parentRect.right;

    let positionClass = 'top';

    if (spaceAbove < tooltipRect.height && spaceBelow > tooltipRect.height) {
      positionClass = 'bottom';
    } else if (spaceLeft < tooltipRect.width && spaceRight > tooltipRect.width) {
      positionClass = 'right';
    } else if (spaceRight < tooltipRect.width && spaceLeft > tooltipRect.width) {
      positionClass = 'left';
    }

    // remove all position classes
    this.renderer.removeClass(tooltip, 'top');
    this.renderer.removeClass(tooltip, 'bottom');
    this.renderer.removeClass(tooltip, 'left');
    this.renderer.removeClass(tooltip, 'right');

    this.renderer.addClass(tooltip, positionClass);
  }*/




  getSelectedSlotTopOffsetByCourtId(courtId?: string): string {
    if (!courtId) {
      return '0px';
    }
    const courtIndex = this.availabilityStore.courtsForSport().findIndex(court => court.id === courtId);
    return (courtIndex * 40) + 'px';
  }

  unselectSlot() {
    this.selectedSlotStore.unselectSlot();
    this.overlayRef?.detach();
    this.tooltipComponentRef = undefined;
    if (this.backdropClickSubscription) {
      this.backdropClickSubscription.unsubscribe();
    }
  }

  isDurationPriceSelected(durationPrice: DurationPrice): boolean {
    return this.selectedSlotStore.isDurationSelected()(durationPrice.duration);
  }

  selectDurationPrice(durationPrice: DurationPrice) {
    this.selectedSlotStore.selectDuration(durationPrice.duration);
  }

  getContinueButtonLabel(): string {
    if (this.isEmbedded) {
      return $localize `Continue with CircleSquare` + ' - ' + this.formatPrice(this.selectedSlotStore.selectedDuration()?.price!);
    }
    return $localize `Continue` + ' - ' + this.formatPrice(this.selectedSlotStore.selectedDuration()?.price!);
  }

  formatPrice(price: number): string {
    return formatCurrency(price, this.locale, '€', 'EUR');
  }

  platformId = inject(PLATFORM_ID);

  activatedRoute = inject(ActivatedRoute);

  membershipTypesEligibleForBookingSelectedSlot = computed(() => {
    const selectedCourtId = this.selectedSlotStore.selectedSlot()?.court.id;
    const membershipTypes = this.clubStore.club()?.courts.find(court => court.id === selectedCourtId)?.membershipTypesEligibleForCourtBooking ?? [];
    return membershipTypes;
  });

  membershipTypeIdsEligibleForBookingSelectedSlot = computed(() => {
    return this.membershipTypesEligibleForBookingSelectedSlot().map(mt => mt.id);
  });

  playerSelfActiveMemberships = toSignal(this.store.select(selectPlayerSelfActiveMembershipTypes));
  playerSelfActiveMembershipTypeIds = computed(() => {
    const ids = this.playerSelfActiveMemberships()?.map(membership => membership.merchantMembershipTypeId) ?? [];
    console.log('playerSelfActiveMembershipTypeIds', ids);
    return ids;
  });

  bookSlot() {

    if (this.isEmbedded) {
      console.log('embedded checkout');
      // redirect whole page to checkout
      if (isPlatformBrowser(this.platformId)) {
        const current = window.document.location.href;
        const clubId = this.clubStore.club()!.id;
        const selectedDate = this.selectedSlotStore.selectedSlot()!.startDateTime().toISODate();
        const selectedSlotStartIso = this.selectedSlotStore.selectedDuration()!.startTime.toISOString()
        const selectedSlotDuration = this.selectedSlotStore.selectedDuration()!.duration;

        window.top!.location.href = `${environment.appUrl}/clubs/${clubId}?date=${selectedDate}&selectedSlotStart=${selectedSlotStartIso}&selectedSlotDuration=${selectedSlotDuration}&selectedCourt=${this.selectedSlotStore.selectedSlot()!.court.id}`;
      }
      return;
    }

    /*if (this.membershipTypeIdsEligibleForBookingSelectedSlot().length > 0) {
      if (this.membershipTypeIdsEligibleForBookingSelectedSlot().some(id => this.playerSelfActiveMembershipTypeIds().includes(id))) {
        this.selectedSlotStore.setCheckoutInProgress(true);
        this.clubsService.courtReservationByFree({
          courtId: this.selectedSlotStore.selectedSlot()!.court.id,
          startTime: this.selectedSlotStore.selectedDuration()!.startTime,
          duration: this.selectedSlotStore.selectedDuration()!.duration,
        }).subscribe({
          next: (reservation) => {
            console.log('courtReservationByFree', reservation);
            this.selectedSlotStore.unselectSlot();
            this.availabilityStore.loadCourtAvailability();
            this.router.navigate(['/my-bookings']);
          },
          error: (error) => {
            this.store.dispatch(showErrorToast({ error }));
          },
          complete: () => {
            this.selectedSlotStore.setCheckoutInProgress(false);
          }
        });
        return;
      }
    }*/

    const data: CartCheckoutDialogData = {
      courtReservationSlot: {
        courtId: this.selectedSlotStore.selectedSlot()!.court.id,
        startTime: this.selectedSlotStore.selectedDuration()!.startTime,
        duration: this.selectedSlotStore.selectedDuration()!.duration,
      },
      eligibleMerchantMembershipTypes: this.membershipTypesEligibleForBookingSelectedSlot(),
    };

    let dialog = this.dialogService.open(CartCheckoutDialogComponent, {
      header: $localize `Checkout`,
      width: '450px',
      baseZIndex: 10000,
      style: { 'overflow': 'auto' },
      contentStyle: { 'max-height': '100vh', 'overflow': 'auto' },
      data: data,
    })
    dialog.onClose.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((result: any) => {
      this.selectedSlotStore.setCheckoutInProgress(false);
      if (typeof result === 'string') {
        console.log('checkout success', result);
        this.selectedSlotStore.unselectSlot();
        this.availabilityStore.loadCourtAvailability();

        if (result === 'CASH') {
          this.router.navigate(['/my-bookings']);
          return;
        }
        this.router.navigate([], {
          queryParams: {
            payment_intent: result, payment_intent_client_secret: null, redirect_status: null
          }, queryParamsHandling: 'merge', replaceUrl: true
        });
      }
    });
    return;
  }

}
